import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../components/seo"
import CCBreadcrumb from '../components/layout/breadcrumb'

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import cardImage from '../queries/images/card-image'

import nasaIcon from '../images/logos/nasa_logo.svg'


const IndexPage = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - The Carbon Cycle and the Science of Climate" />
    <CCBreadcrumb
      pathname={props.location.pathname}
      title={'Carbon Connections Student Home'}
      replace={props.replace}
    />
    <Container fluid className="mb-5">
      <Row className="d-flex">
        <Col>
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <Card>
                    <Card.Header className="cardHeader">
                      <h1 className="headerFontBlue">Carbon Connections</h1>
                      <p className="lead">The Carbon Cycle and the Science of Climate</p>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <Row>
                        <Col>
                          <div className="nasaIcon">
                            <img
                              className="image image-bordered img-fluid"
                              src={ nasaIcon }
                              alt="NASA icon"
                              style={{ border: '1px solid rgb(230, 231, 232)' }}
                            />
                            <div className="image-caption image-caption-bordered">
                              Project Funded By The National Aeronautics and Space Administration <br />
                              Award #NNX10AB56A
                            </div>
                          </div>

                          <p>
                            <em>Carbon Connections</em> is a three-unit, online curriculum for grades 9-12 that was designed to improve your understanding of the carbon cycle and the science of Earth's climate. Each of the three units includes five lessons. Each lesson includes focus questions, hands-on activities, virtual field trips, and interactive models. The concepts covered in the lessons span all science disciplines.
                          </p>

                          <p>
                            The three units follow carbon in past, present, and future contexts. The five lessons in each unit are designed to engage and challenge you by allowing you and your classmates to manipulate NASA and other data. Investigating the evidence of climate change is an important and timely matter. It's also important for you to know what you can do to address the issue. As you work through <em>Carbon Connections</em>, you will see that there are some easy ways for you to be a part of the solution. This supports your learning about the carbon cycle and the science of climate.
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col>
                          <h2>While investigating the carbon cycle and the science of Earth's climate in each of the units, you will develop your understanding of:</h2>

                          <Row className="mt-4">
                            <Col md={12} lg={4}>
                              <Card className="h-100">
                                <Card.Body className="cardBodyBackground">
                                  <h2 className="h5">
                                    Unit 1: Carbon &amp; Climate in the Past
                                  </h2>
                                  <Img
                                    className="mb-3"
                                    fluid={props.data.image1.childImageSharp.fluid}
                                    alt="Unit 1 home"
                                  />
                                  <ul>
                                    <li>Linking the carbon cycle and climate science</li>
                                    <li>Using geologic records to infer Earth's past climates</li>
                                    <li>Studying the multidisciplinary nature of climate science</li>
                                    <li>Testing forcing factors, responses, and systems-thinking</li>
                                    <li>Thinking of oceans as a carbon reservoir</li>
                                  </ul>
                                </Card.Body>
                                <Card.Footer>
                                  <div className="w-100">
                                    <Link to="/unit-1/1.0-overview/">
                                      <Button className="w-100">
                                        Carbon &amp; Climate in the Past&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                                      </Button>
                                    </Link>
                                  </div>
                                </Card.Footer>
                              </Card>
                            </Col>

                            <Col md={12} lg={4}>
                              <Card className="h-100">
                                <Card.Body className="cardBodyBackground">
                                  <h2 className="h5">
                                    Unit 2: Carbon Now
                                  </h2>
                                  <Img
                                    className="mb-3"
                                    fluid={props.data.image2.childImageSharp.fluid}
                                    alt="Unit 2 home"
                                  />
                                  <ul>
                                    <li>Photosynthesis, respiration, carbon sources, and sinks</li>
                                    <li>Carbon transport in a breathing biosphere</li>
                                    <li>Models of carbon cycling</li>
                                    <li>The use of fossil fuels by humans</li>
                                    <li>The connection between energy use and the carbon cycle</li>
                                  </ul>
                                </Card.Body>
                                <Card.Footer>
                                  <div className="w-100">
                                    <Link to="unit-2/2.0-overview/">
                                      <Button className="w-100">
                                        Carbon Now&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                                      </Button>
                                    </Link>
                                  </div>
                                </Card.Footer>
                              </Card>
                            </Col>

                            <Col md={12} lg={4}>
                              <Card className="h-100">
                                <Card.Body className="cardBodyBackground">
                                  <h2 className="h5">
                                    Unit 3: Carbon in the Future &amp; You
                                  </h2>
                                  <Img
                                    className="mb-3"
                                    fluid={props.data.image3.childImageSharp.fluid}
                                    alt="Unit 3 home"
                                  />
                                  <ul>
                                    <li>Records of Earth's temperature (1890-2011)</li>
                                    <li>Climate variability versus climate change</li>
                                    <li>Climate models to test temperature forcings (1979-2010)</li>
                                    <li>Reducing your carbon footprint</li>
                                    <li>How to critically evaluate claims about carbon and climate</li>
                                  </ul>
                                </Card.Body>
                                <Card.Footer>
                                  <div className="w-100">
                                    <Link to="/unit-3/3.0-overview/">
                                      <Button className="w-100">
                                        Carbon in the Future &amp; You&nbsp;&nbsp;<i className="fas fa-arrow-right"></i>
                                      </Button>
                                    </Link>
                                  </div>
                                </Card.Footer>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "student-pages/home/02_unit1_home.jpg" }) {
      ...cardImage
    }
    image2: file(relativePath: { eq: "student-pages/home/03_unit2_home.jpg" }) {
      ...cardImage
    }
    image3: file(relativePath: { eq: "student-pages/home/04_unit3_home.jpg" }) {
      ...cardImage
    }
  }
`
